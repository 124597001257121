import {
    NavLink,
    NavText,
} from 'components/_layout/Navigation/Navigation.styled';
import { Section } from 'components/_layout/Section/Section';
import { StyledLinkIcon } from 'components/_layout/SocialMediaIcons';
import * as Common from 'components/_universal/Common';
import { graphql, navigate } from 'gatsby';
import { useTheme } from 'layout/ThemeContext';
import React, { useEffect, useState } from 'react';
import { TagTile } from 'sections/Blog/BlogItem/BlogItem.styled';
import { ReadMoreForm } from 'sections/Blog/ReadMoreForm';
import { COOKIES_EMAIL_FOR_ARTICLE } from 'shared/localStorage';
import { ROUTE_BLOG } from 'shared/paths';
import {
    FacebookIcon,
    GoodFirmsIcon,
    LinkedInIcon,
    TwitterIcon,
} from 'shared/svg/blogIcons';
import he from 'he';
import {
    AuthorAvatar,
    AuthorDesctiption,
    AuthorInfoWrapper,
    AuthorName,
    AuthorWrapper,
    BlurredContent,
    PostDate,
    PostMainCopyright,
    PostPageContent,
    PostShareBox,
    PostShareItemTitle,
    PostShareLink,
    PostTitle,
} from 'src/sections/Blog/PostPage/PostPage.styled';

export default ({ location, data }: any) => {
    const wpData = data.wordpressPost;
    const { setBlogTagsSelected, setActiveCategory } = useTheme();
    const [content, setContent] = useState('');
    const [blurredContent, setBlurredContent] = useState('');

    // NOTE: form height needs to be calculated to draw share container properly under
    const [formHeight, setFormHeight] = useState('420px');

    const handleTagClick = (e: Event, tag: string) => {
        e.stopPropagation();
        setActiveCategory('all');
        setBlogTagsSelected([tag]);
        navigate(ROUTE_BLOG);
    };

    const parseContent = async () => {
        const savedEmail = localStorage.getItem(COOKIES_EMAIL_FOR_ARTICLE);
        const inputData = { email: savedEmail };

        const response = await fetch(
            'https://wordpress.codahead.com/wp-json/v1/blog/subscribe/verify',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(inputData),
            },
        );

        const data = await response.json();

        if (data.status) {
            setContent(wpData.content);
        } else {
            if (wpData.content.length) {
                const lines = wpData.content.split('\n');
                const allowedContentLength = Math.floor(lines.length * 0.5);
                const blurredContent = lines
                    .slice(allowedContentLength)
                    .slice(0, 20);
                setContent(lines.slice(0, allowedContentLength).join('\n'));
                setBlurredContent(blurredContent.join('\n'));
            }
        }
    };

    useEffect(() => {
        parseContent();
    }, [wpData]);

    return (
        <Common.Div mt={6}>
            <Section variant="secondary" isFirst>
                <Common.Div flex="row">
                    <NavLink isActive={false} to={ROUTE_BLOG}>
                        blog
                    </NavLink>
                    <NavText> {'>'}</NavText>
                    <NavText isActive={true}>{he.decode(wpData.title)}</NavText>
                </Common.Div>
                <PostTitle>{he.decode(wpData.title)}</PostTitle>
                <PostDate>{wpData.date}</PostDate>
                <Common.Div flex="row" gap="8px" mb={5} flexWrap="wrap">
                    {wpData.tags.map(({ name }: any) => (
                        <TagTile
                            onClick={(e) => handleTagClick(e, name)}
                            key={name}
                        >
                            {' #' + name}
                        </TagTile>
                    ))}
                </Common.Div>
                {wpData.acf.image_copyright && (
                    <PostMainCopyright
                        dangerouslySetInnerHTML={{
                            __html: wpData.acf.image_copyright,
                        }}
                    />
                )}
                <PostPageContent
                    dangerouslySetInnerHTML={{
                        __html: content,
                    }}
                />
                {blurredContent.length && (
                    <Common.Div
                        position="relative"
                        h={formHeight}
                        overflow="hidden"
                    >
                        <ReadMoreForm setFormHeight={setFormHeight} />
                        <BlurredContent
                            dangerouslySetInnerHTML={{ __html: blurredContent }}
                        />
                    </Common.Div>
                )}
                <PostShareBox mt={2}>
                    <PostShareItemTitle>Share</PostShareItemTitle>
                    <PostShareLink
                        href={`https://twitter.com/intent/tweet/?text=${wpData.title}&url=https://codahead.com${location.pathname}/`}
                        target="_blank"
                    >
                        <StyledLinkIcon>
                            <TwitterIcon />
                        </StyledLinkIcon>
                    </PostShareLink>
                    <PostShareLink
                        href={`https://www.facebook.com/sharer/sharer.php?u=https://codahead.com${location.pathname}`}
                        target="_blank"
                    >
                        <StyledLinkIcon>
                            <FacebookIcon />
                        </StyledLinkIcon>
                    </PostShareLink>
                    <PostShareLink
                        href={`https://www.linkedin.com/shareArticle?mini=true&url=https://codahead.com${location.pathname}&title=${wpData.title}&summary=${wpData.title}&source=https://codahead.com`}
                        target="_blank"
                    >
                        <StyledLinkIcon>
                            <LinkedInIcon />
                        </StyledLinkIcon>
                    </PostShareLink>
                    <PostShareLink
                        href={`https://www.goodfirms.co/company/codahead`}
                        target="_blank"
                        title="Top Software Development Company"
                    >
                        <StyledLinkIcon>
                            <GoodFirmsIcon />
                        </StyledLinkIcon>
                    </PostShareLink>
                </PostShareBox>
                <PostShareLink
                    href={`https://www.goodfirms.co/directory/country/top-software-development-companies/pl?rate%5B2%5D=%2425+-+%2449&reviews=1`}
                    target="_blank"
                >
                    Codahead's position under Top Software Development Company
                    (Poland)
                </PostShareLink>
                <AuthorWrapper>
                    <AuthorAvatar
                        src={wpData.author.avatar_urls.wordpress_48}
                        alt="avatar"
                    />
                    <AuthorInfoWrapper>
                        <AuthorName>{wpData.author.name}</AuthorName>
                        <AuthorDesctiption>
                            {wpData.author.description}
                        </AuthorDesctiption>
                    </AuthorInfoWrapper>
                </AuthorWrapper>
            </Section>
        </Common.Div>
    );
};

export const postPageQuery = graphql`
    query ($id: String) {
        wordpressPost(id: { eq: $id }) {
            id
            date(formatString: "MMMM DD, YYYY")
            title
            content
            categories {
                id
                name
            }
            tags {
                id
                name
            }
            author {
                name
                description
                avatar_urls {
                    wordpress_48
                }
            }
            slug
            acf {
                image {
                    source_url
                }
                image_copyright
            }
        }
    }
`;
